.marquee_wrapper {
  @apply flex items-center w-screen overflow-hidden h-96 py-1.6;
}

.marquee {
  @apply flex items-center w-full h-full transform;
  animation: scroll_mobile 60s linear infinite alternate;
}

@media (min-width: 1024px) {
  .marquee {
    animation: scroll_desktop 120s linear infinite alternate;
  }
}

.marquee:hover {
  animation-play-state: paused;
}

.img_wrapper {
  @apply relative flex-shrink-0 mx-4.6 w-1/3 h-full transition-all duration-300 ease-in opacity-25 filter blur-md lgt:w-1/4;
}

.img_wrapper:hover {
  @apply opacity-100 filter blur-0;
}

@keyframes scroll_mobile {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-310%);
  }
}

@keyframes scroll_desktop {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-220%);
  }
}
